import type { KippieProduct, Order } from "@shopware-pwa/types";
import aa from "search-insights";

export const useAlgoliaSearchInsights = () => {
	const {
		public: { algolia }
	} = useRuntimeConfig();
	const { sessionContext } = useShopwareSession();
	const { isLoggedIn } = useUser();

	watch(isLoggedIn, (loggedIn) => {
		if (loggedIn) {
			aa("setUserToken", sessionContext.value?.token);
		}
	});

	const defaultEventInformation = computed(() => {
		return {
			userToken: sessionContext.value?.token,
			authenticatedUserToken: sessionContext.value?.token
		};
	});

	const objectIDsEmpty = (objectIDs: any[]) => {
		return objectIDs.length === 0;
	};

	aa("init", {
		appId: (algolia as Record<string, string>).applicationId,
		apiKey: (algolia as Record<string, string>).apiSearchKey,
		useCookie: true,
		...defaultEventInformation.value
	});

	const clickedObjectIDs = (objectIDs: string[], index = "products") => {
		if (objectIDsEmpty(objectIDs)) {
			return;
		}
		aa("clickedObjectIDs", {
			...defaultEventInformation.value,
			eventName: "Product Clicked",
			index,
			objectIDs
		});
	};

	const addedToCartObjectIDs = (objectIDs: string[], products: KippieProduct[], index = "products") => {
		if (objectIDsEmpty(objectIDs)) {
			return;
		}
		aa("addedToCartObjectIDs", {
			...defaultEventInformation.value,
			eventName: "Product Added To Cart",
			index,
			objectIDs,
			objectData: products.map((product) => ({
				price: product.calculatedPrice.totalPrice
			})),
			value: products.reduce((acc, product) => acc + product.calculatedPrice.totalPrice, 0).toFixed(2),
			currency: "EUR"
		});
	};

	const purchasedObjectIDs = (order: Order, index = "products") => {
		const { lineItems } = order;
		aa("purchasedObjectIDs", {
			...defaultEventInformation.value,
			eventName: "Product Purchased",
			index,
			objectIDs: lineItems?.map((lineItem) => lineItem.productId) ?? [],
			objectData:
				lineItems?.map((lineItem) => ({
					price: lineItem.unitPrice,
					quantity: lineItem.quantity
				})) ?? [],
			value: order.amountTotal,
			currency: "EUR"
		});
	};

	const viewedObjectIDs = (objectIDs: string[], index = "products") => {
		if (objectIDsEmpty(objectIDs)) {
			return;
		}
		aa("viewedObjectIDs", {
			...defaultEventInformation.value,
			eventName: "Product Viewed",
			index,
			objectIDs
		});
	};

	return {
		clickedObjectIDs,
		addedToCartObjectIDs,
		purchasedObjectIDs,
		viewedObjectIDs
	};
};
